<template>
  <li>
    <div  v-bind:class="{'active': active}">
      <span class="counter">{{counter}}</span><span class="title">{{ title }}</span>
    </div>
  </li>
</template>

<script>
export default {
  name: "W4MstreakElement",
  props: [
    "title",
    "backgroudColor",
    "textColor",
    "active",
    "counter"
  ],
};
</script>
<style scoped>
.counter {
  font-weight: bold;
  font-size: 25px;
}

.title {
  margin-left: 10px;
  margin-right: 15px;
}

li:before {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  left: 0px;
  border-style: solid;
  border-width: 30px 0 30px 25px;
  border-color: transparent transparent transparent #fff;
  z-index: 0;
}

li:not(:last-child) div:after {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  right: -24px;
  border-style: solid;
  border-width: 30px 0 30px 25px;
  border-color: transparent transparent transparent v-bind(backgroudColor);
  z-index: 10;
}

li.active div {
  font-weight: bold;
  font-size: 15px;
  z-index: 100;
}


li div {
  display: block;
  background: v-bind(backgroudColor);
  color: v-bind(textColor);
}

li div:hover {
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
}

li div:hover:after {
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
}

.active {
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
}
</style>
